<template>
<section class="home_m">
   <div class="box1" ref="touchDom">
      <el-carousel
         arrow="never"
         :interval="5000"
         ref="elCarousel">
         <el-carousel-item v-for="item in $t('locale')=='zh'?carousel:carousel_en" :key="item">
            <img :src="item" alt="" class="carouselBox" />
         </el-carousel-item>
      </el-carousel>
   </div>
   <div class="box2" v-html="$t('PC.Home.rh04y2')">
   </div>
   <div class="box3">
      <div class="box3_con">
         <p>{{ $t("PC.Home.zm90do") }}</p>
         <span v-html="$t('PC.Home.t0xq9e')">
         </span>
      </div>
      <img src="@/assets/images-mobile/home_img1.jpg" alt="" />
   </div>
   <div class="box4">
      <p class="title">{{ $t("PC.Home.prp929") }}</p>
      <div class="box4_con">
         <img src="../../assets/zh/images-mobile/home_img2@2x.png" alt="" />
         <div class="content">
            <p class="title_in">{{ $t("PC.Home.nv4p1e") }}</p>
            <div class="buttom_block">
               <span></span>
            </div>
            <p class="text">
               {{ $t("PC.Home.3zjlxo") }}
            </p>
         </div>
      </div>
      <div class="box4_con">
         <img src="../../assets/zh/images-mobile/home_img3@2x(1).png" alt="" />
         <div class="content">
            <p class="title_in">{{ $t("PC.Home.5g9lzo") }}</p>
            <div class="buttom_block">
               <span></span>
            </div>
            <p class="text">
               {{ $t("PC.Home.gzbzzk") }}
            </p>
         </div>
      </div>
      <div class="box4_con last">
         <img src="../../assets/zh/images-mobile/home_img3@2x(2).png" alt="" />
         <div class="content">
            <p class="title_in">{{ $t("PC.Home.8u1oqi") }}</p>
            <div class="buttom_block">
               <span></span>
            </div>
            <p class="text">
               {{ $t("PC.Home.oyq47m") }}
            </p>
         </div>
      </div>
   </div>
   <div class="box5">
      <p class="title">{{ $t("PC.Home.0z4jnq") }}</p>
      <span>{{ $t("PC.Home.jlqpmm") }}</span>
   </div>
   <div class="box6">
      <p class="title">{{ $t("PC.Home.77me4c") }}</p>
      <figure>
         <div class="if_box" v-show="homeNewsList">
            <HomeSwiperPreloading ref="myChildren" :homeNewsList="homeNewsList"></HomeSwiperPreloading>
         </div>
      </figure>
   </div>
   <FooterB></FooterB>
</section>
</template>

<script>
import FooterB from "./components/FooterB";
import HomeSwiperPreloading from "./components/HomeSwiperPreloading";
export default {
   components: {
      FooterB,
      HomeSwiperPreloading
   },
   data() {
      return {
         startLocation: null,
         endLocation: null,

         homeNewsList: null,
         translateXInitValue: 0, //切图橱窗偏移值
         swiperStyle: `transform: translate3d(${this.translateXInitValue}Px, 0Px, 0Px);`,
         tipsStyle: "opacity:0;",
         carousel: [
            require('@/assets/zh/images-mobile/home_banner1@2x.png'),
            require('@/assets/zh/images-mobile/home_banner2@2x.png'),
            require('@/assets/zh/images-mobile/home_banner3@2x.png'),
         ],
         carousel_en: [
            require('@/assets/en/images-mobile/home_banner1@2x.png'),
            require('@/assets/en/images-mobile/home_banner2@2x.png'),
            require('@/assets/en/images-mobile/home_banner3@2x.png'),
         ]
      };
   },
   created() {
      this.getNewsList();
   },
   mounted() {
      this.addTouchEvent();
   },
   methods: {
      addTouchEvent(e) {
         let touchDom = this.$refs.touchDom;
         let elCarousel = this.$refs.elCarousel;
         let nonePassive = {
            passive: false
         };
         touchDom.addEventListener(
            "touchstart",
            e => {
               this.startLocation = null;
               this.startLocation = e.changedTouches[0].pageX;
            },
            nonePassive
         );
         touchDom.addEventListener(
            "touchend",
            e => {
               this.endLocation = null;
               this.endLocation = e.changedTouches[0].pageX;
               if (this.endLocation > this.startLocation) {
                  elCarousel.prev();
               } else if (this.endLocation < this.startLocation) {
                  elCarousel.next();
               }
            },
            nonePassive
         );
      },

      newsHover(e) {
         e.target.style.top = "-10Px";
         e.target.children[1].children[2].style.left = "6Px";
      },
      newsLeave(e) {
         e.target.style.top = "0Px";
         e.target.children[1].children[2].style.left = "0Px";
      },
      toNewsPage(title, id) {
         this.$router.push({
            name: "NewsPage",
            params: {
               id
            }
         });
      },
      prev(value) {
         this.translateXInitValue += value;
         this.swiperStyle = `transform: translate3d(${this.translateXInitValue}Px, 0Px, 0Px);`;
      },
      next(value) {
         this.translateXInitValue -= value;
         this.swiperStyle = `transform: translate3d(${this.translateXInitValue}Px, 0Px, 0Px);`;
      },
      routerPush(row) {
         this.$router.push({
            name: row
         });
      },
      getNews() {
         this.axios.get(Global.NEWS_HOME_API).then(res => {
            this.homeNewsList = res.data[0];
         });
      },
      getNewsList() {
         let _this = this;
         async function fn() {
            let _promise = new Promise((resovle, reject) => {
               _this.axios.get(Global.NEWS_HOME_API).then(res => {
                  _this.homeNewsList = res.data[0];
                  resovle();
               });
            });
            await _promise;
            _this.$refs.myChildren.swiperDemo();
         }
         fn();
      }
   }
};
</script>

<style lang="scss" scoped>
.home_m {
   background-color: #ccc;
   word-break: break-word !important;

   .box1 {
      padding-top: 48px;
      position: relative;
      height: 198px;

      .el-carousel.el-carousel--horizontal {
         width: 100%;
         height: 198px;
         overflow-y: hidden;
         position: absolute;
         left: 50%;
         top: 0;
         transform: translate(-50%, 0);
         padding-top: 48px;

         .el-carousel__item {
            height: auto;
         }

         img.carouselBox {
            width: 100%;
            height: 150px;
            margin: 0 auto;
         }
      }
   }

   .box2 {
      width: 100%;
      height: auto;
      background-color: #fff;
      font-size: 12px;
      color: #333;
      line-height: 24px;
      text-align: center;
      padding: 16px 20px;
      box-sizing: border-box;
   }

   .box3 {
      width: 100%;
      height: 216px;
      text-align: center;
      color: #fff;
      position: relative;

      img {
         width: 100%;
         height: 216px;
      }

      .box3_con {
         padding: 0 20px;
         box-sizing: border-box;
         position: absolute;
         top: 0;
         z-index: 1;
         width: 100%;
         height: 100%;

         p {
            font-size: 16px;
            line-height: 22px;
            padding: 16px 0 8px;
         }

         span {
            text-align: center;
            font-size: 12px;
            line-height: 24px;
         }
      }
   }

   .box4 {
      width: 100%;
      background-color: #f5f6f9;
      padding: 20px 0;

      p.title {
         font-size: 16px;
         color: #333;
         line-height: 22px;
         text-align: center;
         padding-bottom: 8px;
      }

      .box4_con {
         margin: 0 auto 16px;
         width: 335px;
         background: #fff;
         box-sizing: border-box;
         background-image: url("../../assets/zh/images-mobile/home_img3.png");

         img {
            width: 335px;
            height: 133px;
         }

         div.content {
            width: 335px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            overflow: hidden;

            p.title_in {
               font-size: 14px;
               color: #333;
               line-height: 20px;
               margin: 12px 16px 2px;
            }

            div.buttom_block {
               line-height: 2px;
               border-bottom: 1px solid rgba($color: #003299, $alpha: 0.18);
               margin: 0 16px 8px;
               position: relative;

               span {
                  position: absolute;
                  bottom: 0;
                  display: inline-block;
                  width: 68px;
                  height: 2px;
                  background: #003299;
               }
            }

            p.text {
               width: 303px;
               height: auto;
               font-size: 12px;
               font-family:   PingFangSC-Regular, PingFang SC;
               color: #666;
               line-height: 21px;
               margin: 0 16px 16px;
            }
         }
      }

      .last {
         margin-bottom: 0;
      }
   }

   .box5 {
      padding: 20px;
      color: #333;
      background-color: #fff;

      p.title {
         font-size: 16px;
         line-height: 22px;
         text-align: center;
         padding-bottom: 8px;
      }
   }

   .box6 {
      background-color: #fff;
      padding: 20px 20px 32px;
      padding-top: 0;

      p.title {
         font-size: 16px;
         line-height: 22px;
         text-align: center;
         padding-bottom: 16px;
      }
   }
}
</style>
